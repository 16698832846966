import { Update } from '@ngrx/entity';
import { createAction, props } from '@ngrx/store';
import { Job } from '@surface-elements/jobs/domain';
import { JobFormValue } from './job.state';

// load jobs
export const loadJobs = createAction('[JOB] Load Jobs');

export const loadJobsSuccess = createAction(
  '[JOB] Load Jobs Success',
  props<{ jobs: Job[] }>()
);

export const loadJobsFailure = createAction(
  '[JOB] Load Jobs Fail',
  props<{ error: string }>()
);

// create job
export const createJob = createAction(
  '[JOB] Create job',
  props<{ job: Job }>()
);

export const createJobFail = createAction(
  '[JOB] Create job Fail',
  props<{ error: string }>()
);

export const createJobSuccess = createAction(
  '[JOB] Create job Success',
  props<Job>()
);

// update job
export const updateJob = createAction(
  '[JOB] Update Job',
  props<{ job: Job; id: string }>()
);

export const updateJobFail = createAction(
  '[JOB] Update Job Fail',
  props<{ error: string }>()
);

export const updateJobSuccess = createAction(
  '[JOB] Update Job Success',
  props<Update<Job>>()
);

// current job
export const setCurrentJob = createAction(
  '[JOB] Set Current Job',
  props<{ currentJobNumber: number | null }>()
);

export const clearCurrentJob = createAction('[JOB] Clear Current Job');

export const setSelectedJobNumbers = createAction(
  '[JOB] Set Selected Job Numbers',
  props<{ selectedJobNumbers: number[] }>()
);

export const initializeCurrentJob = createAction(
  '[JOB] Initialize Current Job'
);

export const SetEditingValueJobAction = createAction(
  '[JOB FORM] Set editing value',
  props<{ editValue: JobFormValue }>()
);

export const resetMyForm = createAction('[JOB FORM] Reset form');
